export const toShortISOString = (date: Date) =>
  `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

export const startOfMonth = (date: Date, offset?: number) => {
  const resultDate = new Date(date);
  if (offset) resultDate.setMonth(resultDate.getMonth() + offset);
  resultDate.setDate(1);
  resultDate.setHours(0, 0, 0, 0);

  return resultDate;
};

export const dateFromYYYYMMDD = (stringDate: string) => {
  const dateArray = stringDate.split("-").map((period) => parseInt(period));
  return new Date(dateArray[0], dateArray[1] - 1, dateArray[2]);
};

export const dateToYYYYMMDD = (date: Date) => {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
};

export const dateToDDMMYYYY = (date: string | Date | undefined) => {
  if (!date) return null;
  const dateObject = typeof date === "string" ? new Date(date) : date;
  if (isNaN(dateObject.getTime())) {
    // Invalid date string
    return null;
  }
  const day = dateObject.getDate().toString().padStart(2, "0");
  const month = (dateObject.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
  const year = dateObject.getFullYear().toString();

  return `${day}.${month}.${year}`;
};

export const isDateInRange = (
  date: Date,
  startDate: Date | undefined,
  endDate: Date | undefined
) => {
  const dateTime = date.getTime();
  const isOlder = !startDate || dateTime >= startDate.getTime();
  const isYounger = !endDate || dateTime <= endDate.getTime();

  return isOlder && isYounger;
};

export const nMonthsAgo = (n: number): Date =>
  new Date(new Date().setMonth(new Date().getMonth() - n));
